const environment = {
  trustPlaceApi: {
    baseUrl: process.env.REACT_APP_TRUST_PLACE_API_BASE_URL,
    accessKeys: {
      noliju: process.env.REACT_APP_NOLIJU_ACCESS_KEY,
    },
  },
  ipApi: {
    baseUrl: process.env.REACT_APP_IP_API_BASE_URL,
    accessKey: process.env.REACT_APP_IP_API_ACCESS_KEY,
  },
  googleAnalytics: {
    key: process.env.REACT_APP_GA_MEASUREMENT_ID,
  },
};

export default environment;
