// Lib dependencies
import React from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

// Providers
import LanguageProvider from './i18n/providers/LanguageProvider/LanguageProvider';
import EntityProvider from './providers/EntityProvider';
import MantineProvider from './mantine/providers/MantineProvider';
import ModalsProvider from './mantine/providers/ModalsProvider';
import GlobalStylesProvider from './mantine/providers/GlobalStylesProvider';

// Router
import Router from './router';
import routesPaths from './router/configs/routesPaths.configs';
import environment from './configs/environment.configs';

const App = () => {
  if (environment.googleAnalytics.key) {
    ReactGA.initialize(environment.googleAnalytics.key);
  }
  const { pathname } = useLocation();

  React.useEffect(() => {
    window.addEventListener('error', (e) => {
      if (e.message === 'ResizeObserver loop limit exceeded') {
        const resizeObserverErrDiv = document.getElementById('webpack-dev-server-client-overlay-div');
        const resizeObserverErr = document.getElementById('webpack-dev-server-client-overlay');
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute('style', 'display: none');
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute('style', 'display: none');
        }
      }
    });
  }, []);

  return (
    <MantineProvider>
      <ModalsProvider>
        <GlobalStylesProvider />
        {pathname !== routesPaths.notFound ? (
          <LanguageProvider>
            <EntityProvider>
              <Router />
            </EntityProvider>
          </LanguageProvider>
        ) : (
          <Router />
        )}
      </ModalsProvider>
    </MantineProvider>
  );
};

export default App;
